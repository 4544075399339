.menu {
  @apply hidden lg:inline-block text-right w-full; /* FIXME breakpoint for Featured */
  @apply font-medium text-sm pr-2;

  & :global(li) {
    @apply inline-block;

    & svg {
      @apply ml-1 w-4 h-4 fill-current inline-block;
    }

    & a {
      @apply block leading-10 px-3;
    }
  }

  &:global(.in-primary) {
    @apply block text-left text-base mt-4;

    & li {
      @apply block;

      & a {
        @apply px-0;
      }
    }
  }
}
