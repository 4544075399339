.support {
  @apply h-full cursor-pointer;
  & :global(.main-item) {
    @apply text-black-100 flex items-center justify-center relative h-full;
    @apply font-serif font-bold transition-colors duration-200 whitespace-nowrap;
    @apply px-4 md:px-7 focus:outline-none bg-green-100;
  }

  & :global(.has-icon .open-in-new) {
    @apply block w-4 h-4 ml-2 fill-current rotate-0;
  }

  & :global(.caret) {
    @apply block w-[9px] h-[5px] ml-2 transition-transform duration-200 transform;
  }

  & :global(.rotate) {
    @apply rotate-180;
  }

  &:global(.in-primary) {
    & :global(.main-item) {
      @apply justify-start p-5 font-sans text-lg;

      & svg {
        @apply absolute right-0 mr-6;
      }
    }

    & :global(ul.support) {
      @apply bg-green-100 px-5;

      & li {
        @apply border-b border-black-100/15 py-4 hover:text-white;
        @apply text-lg text-black-100 font-medium relative;

        & a {
          @apply max-w-[80%];
        }

        & svg {
          @apply top-[1.4rem];
        }

        &:last-child {
          @apply border-0;
        }
      }
    }
  }
}

.submenu {
  @apply absolute w-full right-0 h-screen bg-green-100;
  @apply hidden max-w-sm;

  & :global(.primary li) {
    @apply border-b border-black-100/15 py-4;
    & a {
      @apply max-w-[80%];
    }

    &:last-child {
      @apply border-0;
    }
  }

  & :global(.primary li) {
    @apply hover:text-white;
  }
}

.on {
  @apply block;
}
