.submenu {
  @apply pt-1 sm:pt-4 pb-5.5;

  & :global(> nav) {
    @apply overflow-y-auto px-4 sm:px-8;
    @apply max-h-screen-nav sm:max-h-screen-nav-sm lg:max-h-screen-nav-lg;
  }

  & :global(.primary) {
    @apply text-lg sm:text-1.5xl leading-6 font-bold;
    & > li {
      @apply overflow-hidden relative;
    }
  }

  & :global(li) {
    & :global(.toggle) {
      @apply absolute w-8 aspect-1 top-3 -right-0;
      @apply py-4;
      & svg {
        @apply w-[11px] h-[8px] mx-auto transform rotate-0 transition-transform duration-200;
      }
      & :global(.open) {
        @apply rotate-180 text-summer-green-100;
      }
    }
  }

  & :global(.has-icon li) {
    & svg {
      @apply absolute top-7 right-2;
      @apply transform -rotate-90 w-[11px] h-[8px];
    }
  }

  & :global(.secondary) {
    @apply pt-6 pb-2 font-medium border-b border-black-100/15;

    & > li {
      @apply inline-block w-1/2 pb-4;
    }
  }
}
