.logo {
  @apply inline-flex items-center h-icon-sm sm:h-icon md:h-icon-lg justify-start;

  & :global(.falcon) {
    /* @apply w-icon-sm h-icon-sm sm:w-icon sm:h-icon md:h-icon-lg md:w-icon-lg mr-2.5; */
    @apply w-8 h-8 md:w-13 md:h-13;
    @apply mr-2.5;
  }

  & :global(.text-wrapper) {
    @apply text-[13px] leading-4 pt-1.5;
  }

  & :global(.text) {
    @apply h-4 w-auto;
  }
}
