.search {
  @apply h-full aspect-1 bg-black-100/5 md:relative hover:bg-black-100/10;

  & :global(> button) {
    @apply h-full w-full focus:outline-none flex justify-center items-center;
  }

  & :global(.magnifier) {
    @apply w-4 sm:w-5 fill-current stroke-current;
  }

  & :global(.close) {
    @apply w-3 sm:w-4 fill-current stroke-current;
  }

  & :global(.on) {
    @apply bg-black-100/15;
  }
}

.searchForm {
  @apply absolute bg-white left-0 w-full p-3;
  @apply md:w-85 md:left-auto md:right-0;

  & :global(.magnifier) {
    @apply absolute left-7 top-7 sm:top-8 text-black-100;
  }

  & :global(input) {
    @apply w-full border border-black-100/20 pl-11 p-3 rounded focus:text-black-100 focus:outline-none appearance-none placeholder-black-100;
    @apply sm:py-4;
  }

  & :global(.spinner) {
    @apply absolute w-8 top-3 right-6 opacity-40;
    @apply sm:top-4;
  }
}
