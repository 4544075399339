.footer {
  & :global(.inner-wrapper) {
    @apply mx-auto max-w-8xl;
  }

  @apply relative bg-black-100 text-white py-10 px-5 lg:px-18;

  & :global(.logos) {
    @apply flex justify-between border-b border-[#343434] pb-5;

    & .logo {
      @apply text-white;
    }

    & :global(.ninety) {
      @apply h-[25px] mt-1;
    }
  }

  & :global(.section) {
    @apply lg:grid lg:gap-12 lg:border-b border-[#343434] pt-4 pb-5;

    &:global(.columns-2) {
      @apply grid-cols-2;
    }

    &:global(.columns-3) {
      @apply grid-cols-3;
    }

    &:global(.columns-4) {
      @apply grid-cols-4;
    }
  }

  & :global(.column) {
    @apply border-b border-[#343434] pt-4 pb-5;
    @apply lg:border-0;

    & h4 {
      @apply text-lg font-sans pb-4;
    }

    & li {
      @apply pb-4;
    }

    & p {
      @apply pb-4 pr-6;

      &:last-child {
        @apply pb-0;
      }
    }

    & a {
      @apply underline;
    }
  }

  & :global(.socials) {
    & ul {
      @apply flex flex-wrap;
    }

    & li {
      @apply w-13 h-13 rounded-full bg-[#282828] mr-4 mb-4;
      &:last-child {
        @apply mb-0;
      }

      & a {
        @apply w-13 h-13 flex items-center justify-center;
      }

      & svg {
        @apply h-5 w-auto;
      }
    }
  }

  & :global(.bottom-row) {
    @apply text-base flex flex-col lg:flex-row lg:justify-between lg:items-center pt-5;

    & :global(.publisher) {
      @apply mb-2;
    }

    & a {
      @apply underline;
    }
  }
}

@media print {
  .footer {
    @apply hidden;
  }
}
